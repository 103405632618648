export default [
  { name: '首頁', imgSrc: '', subTitle: 'Index', section: 'section1', svgSrc: '' },
  { name: '建築外觀', imgSrc: '', subTitle: 'Building Exterior', section: 'section3', svgSrc: '' },
  { name: '建築特色', imgSrc: '', subTitle: 'Features', section: 'section4', svgSrc: '' },
  { name: '設計規劃', imgSrc: '', subTitle: 'Location', section: 'section5', svgSrc: '' },
  { name: '優質團隊', imgSrc: '', subTitle: 'About', section: 'section6', svgSrc: '' },
  { name: '生活版圖', imgSrc: '', subTitle: 'Life Function', section: 'section7', svgSrc: '' },
  { name: '預約賞屋', imgSrc: '', subTitle: 'Contact Info', section: 'contact', svgSrc: '' },
]
// 平面圖集 (連結到：平面圖)?? (先不處理)
